.iconDetails {
    margin-left:10%;
   float:left; 
   height:40px;
   width:40px; 
   }

.container2 {
    margin-top: 10px;
    width:100%;
    height: 64px;
    padding:1%;
}   