@media only screen and (max-width: 550px) {
    .hideforShortScreen{
        display: none;
    }
    
}
@media only screen and (min-width: 550px) {
    .hideforShortScreen{
        display: inline-block;
    }
}
