.allTextInput {
  border-radius: 5px;
  box-shadow: 5px 5px 2px #e5e2e2;
  padding: 7px;
}

.ant-steps-finish-icon {
  vertical-align: middle;
}

.jumbotron {
  background-color: white;
}

.ant-form-item-control {
  border-radius: 5px;
}

code {
  border-radius: 5px;
  box-shadow: 5px 5px 2px #e5e2e2;
  padding: 10px;
}

.workingArea {
  background-color: white;
}

.backImage {
  background-image: url(./bg.png);
}

.stepBar {
  border-radius: 20px;
  background-image: linear-gradient(to right, rgba(255, 0, 0, 0), #030029b4);
}
.ant-menu-item-selected {
  background: linear-gradient(14deg, #0656ff 0%, rgba(6, 86, 255, 0.6));
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}
.emphasisColor {
  background: rgb(56, 90, 161);
}
.white {
  color: white;
}

.txtAlignCenter {
  text-align: center;
}

.file-box-content {
  background-color: white;
  min-height: 500px;
  padding-top: 15px;
}

.file-box-content .file-box {
  border: 2px solid #1890ff;
  border-radius: 5px;
  padding: 16px;
  width: 160px;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 16px;
  margin-left: 5px;
  margin-top: 5px;
  background-color: #f0f2f5;
}

.ResouceMaterialTree .ant-tree-list-holder-inner {
  min-height: 500px;
}

.text-muted {
  color: #a4abc5 !important;
}

.file-box-content .ant-btn-icon-only {
  height: 20px;
  width: 20px;
  transform: rotate(90deg);
  padding-top: 10px;
}
.file-box-content .ant-btn {
  padding: 0;
}

.file-box-content .ant-btn-group {
  padding-top: 6px;
}
